import { useEffect, useMemo, Fragment } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { object, string } from 'yup';

// mui
import { Box, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { FormGrid, Tooltip } from 'components';

import { RISK_DEFINITION_GENERAL } from 'consts';
import * as utils from 'utils';

import RiskDataAutocompleteAsyncValue from './RiskDataAutocompleteAsyncValue';

// app
import styles from './RiskData.styles';

//Risk confirmation page
const RiskData = ({ handleStep, groups, definitionsFields, riskDataValues, isReQuote, isEndorsement, productType }) => {
  const classes = makeStyles(styles, { name: 'RiskData' })();
  const { control } = useFormContext();

  const riskValues = useWatch({
    control,
  });
  const postPatchRisk = useMemo(() => ({ ...riskDataValues, ...riskValues }), [riskValues]);

  useEffect(() => {
    if (isEndorsement || isReQuote) {
      for (const [key, value] of Object.entries(postPatchRisk)) {
        if (Array.isArray(value) && value?.length === 0) {
          postPatchRisk[key] = null;
        }
      }
    }
  }, [postPatchRisk]);

  const patch = useMemo(
    () => (isEndorsement || isReQuote ? utils.diff.endorsementDiff(riskDataValues, postPatchRisk) : []),
    [riskDataValues, postPatchRisk, isEndorsement, isReQuote]
  );

  const formattedJsonPatch = useMemo(
    () =>
      patch?.map((change) => {
        const jsonPath = change.path?.replace('/', '');
        const finalPath = jsonPath?.replace(/\//g, '.');
        return { ...change, path: finalPath };
      }),
    [patch]
  );

  const checkIsFieldEdited = (name) => {
    let isEdited = false;
    formattedJsonPatch?.forEach((change) => {
      let formattedName = name?.replace('[', '.');
      formattedName = formattedName?.replace('].', '.');
      if (formattedName === change.path && change?.op === 'replace') {
        isEdited = true;
      }
    });

    return isEdited;
  };

  const fieldEditedValues = (name, field, value) => {
    const previousValue = get(riskDataValues, `${name}`);
    const currentValue = typeof value !== 'undefined' ? value : get(riskValues, `${name}`);

    return previousValue || currentValue ? (
      <Box minWidth={150}>
        <Grid container spacing={1}>
          <Grid item xs={3} align="left">
            <Typography variant="body2" align="left" component="span">
              {utils.string.t('products.wasLabel')}:
            </Typography>
          </Grid>
          <Grid item xs={9} align="left">
            <Typography variant="body2" align="left" component="span" className={classes.quoteValue}>
              {previousValue || previousValue === false || previousValue === 0 ? renderValue(field, previousValue) : null}
            </Typography>
          </Grid>
          <Grid item xs={3} align="left">
            <Typography variant="body2" align="left" component="span">
              {utils.string.t('products.nowLabel')}:
            </Typography>
          </Grid>
          <Grid item xs={9} align="left">
            <Typography variant="body2" align="left" component="span" className={classes.quoteValue}>
              {currentValue || currentValue === false ? renderValue(field, currentValue) : null}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    ) : null;
  };

  const getOptionLabel = (options) => (value) => {
    const option = options?.find((o) => String(o.value) === String(value)) || {};
    return option?.label !== 'Select...' ? option.label : '';
  };

  const renderValue = (field, v) => {
    const prefix = '';
    let suffix = '';
    const value = typeof v !== 'undefined' ? v : riskValues[field.name];
    let newValue = value;
    switch (field?.type) {
      case 'number':
        if (field.thousandSeparator === false) {
          break;
        }
        newValue = utils.string.t(`format.number`, { value: { number: value } });
        break;
      case 'toggle_group':
        newValue = field.options.find((option) => option.value === value)?.label;
        break;
      case 'toggle':
        newValue = utils.risk.checkBoolean(value);
        break;
      case 'checkbox':
        newValue = utils.risk.checkBoolean(value);
        break;
      case 'datepicker':
        newValue = utils.string.t(`format.date`, { value: { date: value } });
        break;
      case 'time':
      case 'timepicker':
        newValue = typeof value === 'string' ? value : utils.date.formatToTime(value);
        break;
      case 'select':
      case 'selectAsync': {
        const options = utils.generic.isValidArray(field.options, true) ? field.options : [];
        newValue = utils.generic.isValidArray(value, true) ? value.map(getOptionLabel(options)).join(', ') : getOptionLabel(options)(value);
        break;
      }
      case 'radio': {
        newValue = value || '';
        break;
      }
      case 'autocompletemui': {
        if (utils.generic.isValidArray(value)) {
          newValue = '';
          for (const singleValue of value) {
            newValue += `${singleValue?.label || singleValue},`;
          }
          newValue = newValue.slice(0, -1);
        } else {
          const options = utils.generic.isValidArray(field.options, true) ? field.options : [];
          const option = options.find((o) => String(o.value) === String(value)) || {};

          newValue = utils.generic.isValidObject(value) ? value?.label : option?.label ? option?.label : value;
        }
        break;
      }
      case 'autocompletemuiAsync': {
        if (utils.generic.isValidArray(value)) {
          newValue = '';
          for (const singleValue of value) {
            newValue += `${singleValue?.label || singleValue},`;
          }
          newValue = newValue.slice(0, -1);
        } else {
          return (
            <RiskDataAutocompleteAsyncValue
              product={productType}
              dataIndex={field.dataIndex}
              dataSource={field.dataSource}
              valueIndex={field.valueIndex}
              partialMatch={field.partialMatch}
              defaultValue={field.defaultValue}
              value={value}
            />
          );
        }
        break;
      }
      case 'array': {
        if (utils.generic.isValidArray(value)) {
          newValue = value?.map((item, index) => {
            const isLastValue = index === value.length - 1;

            const filteredItem = Object.entries(item).filter(([_, v]) => v);

            const mappedItem = filteredItem.map(([key, v], itemIndex) => {
              const def = field.arrayItemDef.find(({ name }) => name === key);
              const isLastItem = itemIndex === filteredItem.length - 1;
              const valueLabel = field.options?.find(({ value }) => value === v)?.label || v;
              return (
                <Fragment key={key}>
                  {renderValue(def, valueLabel)}
                  {!isLastItem ? ' - ' : ''}
                  {isLastItem && !isLastValue ? '; ' : ''}
                </Fragment>
              );
            });

            return mappedItem;
          });
        } else if (value) {
          newValue = Object.entries(value)
            .filter(([_, v]) => v)
            .map(([key, v]) => {
              const label = field.options?.find(({ value }) => value === key)?.label;

              return `${label}: ${v}`;
            })
            .join(';\n');
        }

        break;
      }
      case 'object':
        if (!addressSchema.isValidSync(value)) {
          break;
        }
        newValue = formatAddress(value);
        break;
      default:
        break;
    }
    // add prefix/suffix for specific fields
    if (field.name === 'distanceToCoast' && value) {
      suffix = ` ${utils.string.t('map.unit.miles')}`;
    }
    // the extra <span /> is used to prevent Material-UI complaining about not receiving a ReactNode
    // this happens if the value is true/false/undefined/null...
    // this workaround prevents errors in case some invalid values fall through the cracks
    return utils.generic.isValidObject(newValue) ? null : (
      <span>
        {prefix}
        {newValue}
        {suffix}
      </span>
    );
  };
  return (
    <FormGrid container spacing={3} data-testid="risk-data">
      {groups.map((group, index) => {
        const fields = utils.risk.getFieldsByGroup(definitionsFields, group) || [];

        return group === RISK_DEFINITION_GENERAL ? (
          <FormGrid item xs={12} data-testid={`risk-data-${group}`} key={group}>
            <Box className={classes.card}>
              <Box p={2} className={classes.cardTitle}>
                <Typography variant="h3" className={classes.cardTitleHeading}>
                  {startCase(toLower(group))}
                </Typography>
                <IconButton onClick={handleStep(index)} size="small" aria-label="edit">
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              </Box>
              <Box p={2}>
                <FormGrid container spacing={1}>
                  {fields.map((field) => {
                    const fieldName = field?.targetField ? `${field.targetField}.name` : field.name;
                    const value = field?.targetField ? riskValues[field.targetField]?.name : riskValues[field.name];
                    const condition = utils.risk.getCondition(field, definitionsFields);
                    const isConditionValid = condition && utils.risk.isConditionValid(condition, riskValues);
                    const isFieldEdited = isReQuote || isEndorsement ? checkIsFieldEdited(fieldName) : false;
                    const isHidden = utils.risk.isHiddenField(field);
                    return !isHidden ? (
                      condition === undefined || (condition && isConditionValid) ? (
                        <FormGrid item xs={12} sm={4} key={`${field.name}-${value}`}>
                          <FormGrid container spacing={1} key={field.label}>
                            <FormGrid item xs={6}>
                              <Typography variant="body2" component="span">
                                {field.label}
                              </Typography>
                            </FormGrid>
                            <FormGrid item xs={6} classes={{ root: classes.flexGrid }}>
                              <Typography
                                variant="body2"
                                component="div"
                                style={{ fontWeight: 'bold' }}
                                classes={{ root: isFieldEdited ? classes.edited : '' }}
                              >
                                {renderValue(field)}
                              </Typography>
                              {isFieldEdited ? (
                                <Tooltip title={fieldEditedValues(fieldName, field)} placement="top" rich style={{ marginLeft: 5 }}>
                                  <InfoOutlinedIcon fontSize="small" />
                                </Tooltip>
                              ) : null}
                            </FormGrid>
                          </FormGrid>
                        </FormGrid>
                      ) : (
                        <FormGrid item xs={12} sm={4} key={`${field.name}-${value}`} />
                      )
                    ) : null;
                  })}
                </FormGrid>
              </Box>
            </Box>
          </FormGrid>
        ) : (
          <FormGrid item xs={12} sm={6} md={4} data-testid={`risk-data-${group}`} key={group}>
            <Box className={classes.card}>
              <Box p={2} className={classes.cardTitle}>
                <Typography variant="h3" className={classes.cardTitleHeading}>
                  {startCase(toLower(group))}
                </Typography>
                <IconButton onClick={handleStep(index)} size="small" aria-label="edit">
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              </Box>
              <Box p={2}>
                {fields
                  .filter((field) => Boolean(field.name) && field.type !== 'label')
                  .map((field) => {
                    const value = riskValues[field.name] || null;
                    const isArrayColumn = utils.risk.isArrayColumn(field) && utils.generic.isValidArray(value);
                    const isArrayTable = utils.risk.isArrayTable(field) && utils.generic.isValidArray(value);
                    const isObject = utils.risk.isObject(field) && utils.generic.isValidObject(value);
                    const valueArray = isArrayColumn || isArrayTable ? value : [value];
                    const originalValueArray =
                      (isArrayColumn || isArrayTable ? riskDataValues?.[field.name] : [riskDataValues?.[field.name]]) ?? [];
                    const arrayKey = isArrayColumn || isArrayTable ? 'arrayItemDef' : isObject ? 'objectDef' : '';

                    const condition = utils.risk.getCondition(field, definitionsFields);
                    const isConditionValid = condition && utils.risk.isConditionValid(condition, riskValues);

                    if (isArrayColumn || isArrayTable || isObject) {
                      const { name } = field;
                      const removedItems =
                        formattedJsonPatch
                          ?.map((change) => {
                            if (change.op === 'remove') {
                              const parent = change.path.split('.')[0];
                              const i = change.path.split('.')[1];
                              if (name === parent) {
                                return riskDataValues[field.name][i];
                              }
                            }
                            return false;
                          })
                          .filter(Boolean) || [];

                      const addedItems =
                        formattedJsonPatch
                          ?.map((change) => {
                            if (change.op === 'add') {
                              const parent = change.path.split('.')[0];
                              const i = change.path.split('.')[1];
                              if (name === parent) {
                                return riskValues[field.name][i];
                              }
                            }
                            return false;
                          })
                          .filter(Boolean) || [];

                      const originalArrayAndAdded = isArrayColumn || isArrayTable ? [...originalValueArray, ...addedItems] : valueArray;

                      let displayArray = [];
                      if (originalArrayAndAdded?.length > 10) {
                        const changedItems = [...addedItems, ...removedItems];
                        const sliceLength = changedItems?.length > 5 ? 5 : 10;
                        displayArray = [
                          ...addedItems,
                          ...removedItems,
                          ...originalArrayAndAdded
                            .filter((v) => !addedItems.includes(v) && !removedItems.includes(v))
                            .slice(0, sliceLength),
                        ];
                      } else displayArray = originalArrayAndAdded;

                      if (!isEndorsement && !isReQuote) {
                        displayArray = valueArray;
                      }

                      const showMoreMessage = originalArrayAndAdded?.length > 10 ? `... and ${originalArrayAndAdded.length - 10} more` : '';

                      return condition === undefined || (condition && isConditionValid) ? (
                        <>
                          <FormGrid item xs={6}>
                            {displayArray?.length ? (
                              <Typography variant="subtitle1" component="span">
                                {field.label}
                              </Typography>
                            ) : null}
                          </FormGrid>
                          <FormGrid spacing={2} container key={`${field.label}-${field.name}`}>
                            {displayArray.map((v, index) => {
                              const isRemoved = removedItems.includes(v);
                              const isAdded = addedItems.includes(v);

                              return (
                                <FormGrid item xs={12} key={`key-${index}`}>
                                  <Box
                                    className={
                                      displayArray?.length > 1
                                        ? `${classes.card} ${classes.cardArray} ${isRemoved ? `${classes.cardRemoved}` : ``}${
                                            isAdded ? `${classes.cardAdded}` : ``
                                          }`
                                        : ''
                                    }
                                  >
                                    {isRemoved ? (
                                      <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
                                        <RemoveCircleIcon fontSize="small" style={{ color: '#FF0000', marginRight: 10 }} />
                                        <Typography variant="h3" classes={{ h3: classes.removedTitle }}>
                                          Removed
                                        </Typography>
                                      </Box>
                                    ) : null}
                                    {isAdded ? (
                                      <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
                                        <AddCircleIcon fontSize="small" style={{ color: '#2CC6AB', marginRight: 10 }} />
                                        <Typography variant="h3" classes={{ h3: classes.addedTitle }}>
                                          Added
                                        </Typography>
                                      </Box>
                                    ) : null}
                                    {field[arrayKey]
                                      .filter((f) => Boolean(f.name))
                                      .map((arrayField) => {
                                        const fieldName = isObject
                                          ? `${field.name}.${arrayField.name}`
                                          : `${field.name}[${index}].${arrayField.name}`;
                                        const jsonPath = fieldName?.replace('[', '.').replace('].', '.');
                                        const isFieldEdited = isReQuote || isEndorsement ? checkIsFieldEdited(fieldName) : false;
                                        const isHidden = utils.risk.isHiddenField(arrayField);
                                        const isTitle = utils.risk.isTitleField(arrayField);
                                        const isLabel = arrayField.type === 'label';

                                        const displayValue =
                                          isFieldEdited && !isRemoved && !isAdded
                                            ? formattedJsonPatch.find((change) => change.path === jsonPath)?.value
                                            : v[arrayField.name];

                                        const arrayValue = formattedJsonPatch.reduce((acc, change) => {
                                          const key = change.path.split('.').pop();
                                          if (key) {
                                            return { ...acc, [key]: change.value };
                                          }
                                          return { ...acc };
                                        }, v);

                                        const arrayCondition = utils.risk.getCondition(arrayField, field[arrayKey]);
                                        const isArrayConditionValid =
                                          arrayCondition && utils.risk.isConditionValid(arrayCondition, arrayValue);

                                        return (!isHidden || isTitle) &&
                                          !isLabel &&
                                          (arrayCondition === undefined || (arrayCondition && isArrayConditionValid)) ? (
                                          <FormGrid spacing={1} container key={`${arrayField.name}-${arrayField.label}`}>
                                            <FormGrid item xs={6}>
                                              <Typography variant="body2" component="span">
                                                {arrayField.label}
                                              </Typography>
                                            </FormGrid>
                                            <FormGrid item xs={6} classes={{ root: classes.flexGrid }}>
                                              <Typography
                                                variant="body2"
                                                component="span"
                                                style={{ fontWeight: 'bold' }}
                                                classes={{ root: isFieldEdited && !isRemoved && !isAdded ? classes.edited : '' }}
                                              >
                                                {renderValue(arrayField, displayValue)}
                                              </Typography>
                                              {isFieldEdited && !isRemoved && !isAdded ? (
                                                <Tooltip
                                                  title={fieldEditedValues(fieldName, arrayField, displayValue)}
                                                  placement="top"
                                                  rich
                                                  style={{ marginLeft: 5 }}
                                                >
                                                  <InfoOutlinedIcon fontSize="small" />
                                                </Tooltip>
                                              ) : null}
                                            </FormGrid>
                                          </FormGrid>
                                        ) : null;
                                      })}
                                  </Box>
                                </FormGrid>
                              );
                            })}
                            {showMoreMessage ? (
                              <FormGrid item xs={12}>
                                <Typography variant="body2" component="span">
                                  {showMoreMessage}
                                </Typography>
                              </FormGrid>
                            ) : null}
                          </FormGrid>
                        </>
                      ) : null;
                    }
                    const isFieldEdited = isReQuote || isEndorsement ? checkIsFieldEdited(field.name) : false;
                    const isHidden = utils.risk.isHiddenField(field);

                    return !isHidden && (condition === undefined || (condition && isConditionValid)) ? (
                      <FormGrid container spacing={2} key={`${field.name}-${field.label}`}>
                        <FormGrid item xs={6}>
                          <Typography variant="body2" component="span">
                            {field.label || field.title}
                          </Typography>
                        </FormGrid>
                        <FormGrid item xs={6} classes={{ root: classes.flexGrid }}>
                          <Typography
                            variant="body2"
                            component="span"
                            style={{ fontWeight: 'bold' }}
                            classes={{ root: isFieldEdited ? classes.edited : '' }}
                          >
                            {renderValue(field)}
                          </Typography>
                          {isFieldEdited ? (
                            <Tooltip title={fieldEditedValues(field.name, field)} placement="top" rich style={{ marginLeft: 5 }}>
                              <InfoOutlinedIcon fontSize="small" />
                            </Tooltip>
                          ) : null}
                        </FormGrid>
                      </FormGrid>
                    ) : null;
                  })}
              </Box>
            </Box>
          </FormGrid>
        );
      })}
    </FormGrid>
  );
};

export default RiskData;

const addressSchema = object({
  street: string(),
  city: string(),
  zipCode: string(),
  county: string(),
  state: string(),
});

const formatAddress = ({ state, county, city, street }) => [state, county, city, street].filter((el) => !!el).join(', ');
