import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components';
import { Add } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isEmpty from 'lodash/isEmpty';

import { RiskContext } from 'forms/AddEditQuoteBind/AddEditQuoteBind';

// app
import * as utils from 'utils';
import { showModal, selectIsProducer, selectPartyClient } from 'stores';

const LabelAndCreate = ({ label, name, setValue, value, targetField }) => {
  const dispatch = useDispatch();
  const hasValueSet = value && (!Array.isArray(value) || utils.generic.isValidArray(value, true)) ? true : false;
  const userIsProducer = useSelector(selectIsProducer);
  const partyClient = useSelector(selectPartyClient);
  const { risk } = useContext(RiskContext);

  const productCode = risk?.riskType;
  const submitHandlerCallback = (values) => {
    if (utils.generic.isFunction(setValue)) {
      const value = {
        id: values.id,
        label: values?.name || '',
        value: values?.id,
      };
      setValue(name, value);
      setValue(targetField, values);
    }
  };

  const handleCreate = {
    clientId: () => {
      hasValueSet
        ? dispatch(
            showModal({
              component: 'EDIT_PRODUCTS_CLIENT',
              props: {
                title: userIsProducer ? 'products.admin.clients.producerInformation' : 'products.admin.clients.edit',
                fullWidth: true,
                maxWidth: 'lg',
                componentProps: {
                  id: value?.value,
                  isCreateClientModal: true,
                  isEdit: true,
                  isAddRiskForm: true,
                  isProducer: userIsProducer,
                  submitHandler: (response) => submitHandlerCallback(response),
                },
              },
            })
          )
        : dispatch(
            showModal({
              component: 'ADD_PRODUCTS_CLIENT',
              props: {
                title: 'risks.createdNewClient',
                fullWidth: true,
                maxWidth: 'lg',
                componentProps: {
                  isCreateClientModal: true,
                  isAddRiskForm: true,
                  productCode: productCode,
                  submitHandler: (response) => submitHandlerCallback(response),
                },
              },
            })
          );
    },
    insuredId: () => {
      hasValueSet
        ? dispatch(
            showModal({
              component: 'EDIT_PRODUCTS_INSURED',
              props: {
                title: 'products.admin.insureds.edit',
                fullWidth: true,
                maxWidth: 'lg',
                componentProps: {
                  id: value?.value,
                  reInsured: false,
                  isCreateInsuredModal: true,
                  isEdit: true,
                  isAddRiskForm: true,
                  submitHandler: (response) => submitHandlerCallback(response),
                },
              },
            })
          )
        : dispatch(
            showModal({
              component: 'ADD_INSURED',
              props: {
                title: 'risks.createdNewInsured',
                fullWidth: true,
                maxWidth: 'lg',
                componentProps: {
                  isAddRiskForm: true,
                  productCode: productCode,
                  submitHandler: (response) => submitHandlerCallback(response),
                },
              },
            })
          );
    },
    reinsuredId: () => {
      hasValueSet
        ? dispatch(
            showModal({
              component: 'EDIT_PRODUCTS_INSURED',
              props: {
                title: 'products.admin.reInsureds.edit',
                fullWidth: true,
                maxWidth: 'lg',
                componentProps: {
                  id: value?.value,
                  reInsured: true,
                  isCreateInsuredModal: true,
                  isEdit: true,
                  isAddRiskForm: true,
                  submitHandler: (response) => submitHandlerCallback(response),
                },
              },
            })
          )
        : dispatch(
            showModal({
              component: 'ADD_INSURED',
              props: {
                title: 'risks.createdNewReInsured',
                fullWidth: true,
                maxWidth: 'lg',
                componentProps: {
                  reInsured: true,
                  isAddRiskForm: true,
                  productCode: productCode,
                  submitHandler: (response) => submitHandlerCallback(response),
                },
              },
            })
          );
    },
  };

  const icon = userIsProducer && targetField === 'client' ? (hasValueSet ? VisibilityIcon : null) : hasValueSet ? VisibilityIcon : Add;
  const hideButton =
    userIsProducer &&
    ((targetField === 'client' && !hasValueSet) ||
      ((targetField === 'insured' || targetField === 'reinsured') && (!partyClient || isEmpty(partyClient))));
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <span>{label}</span>
      {!hideButton ? (
        <Button
          icon={icon}
          size="xsmall"
          text={hasValueSet ? `View` : `Add new`}
          variant="contained"
          color="primary"
          onClick={() => utils.generic.isFunction(handleCreate[name]) && handleCreate[name]()}
        />
      ) : (
        <Button size="xsmall" text="nil" style={{ visibility: 'hidden' }} />
      )}
    </div>
  );
};

export default LabelAndCreate;
